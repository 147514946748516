import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { API, isMobile, showError, showInfo, showSuccess, verifyJSON } from '../../helpers';
import { CHANNEL_OPTIONS } from '../../constants';
import Title from "@douyinfe/semi-ui/lib/es/typography/title";
import { SideSheet, Space, Spin, Button, Input, Typography, Select, TextArea, Checkbox, Banner } from "@douyinfe/semi-ui";
import { Form } from 'semantic-ui-react';


const MODEL_MAPPING_EXAMPLE = {
    'gpt-3.5-turbo-0301': 'gpt-3.5-turbo',
    'gpt-4-0314': 'gpt-4',
    'gpt-4-32k-0314': 'gpt-4-32k'
};

// 根据渠道类型返回相应的鉴权密钥提示信息
function type2secretPrompt(type) {
    // inputs.type === 15 ? '按照如下格式输入：APIKey|SecretKey' : (inputs.type === 18 ? '按照如下格式输入：APPID|APISecret|APIKey' : '请输入渠道对应的鉴权密钥')
    switch (type) {
        case 8:
            return '按照如下格式输入：Ak|Sk|Region';
        default:
            return '请输入渠道对应的鉴权密钥';
    }
}

const EditChannel = (props) => {
    // 使用React Router的hook获取导航功能
    const navigate = useNavigate();
    // 从props获取当前编辑的渠道ID
    const channelId = props.editingChannel.id;
    // 根据渠道ID判断是否为编辑模式
    const isEdit = channelId !== undefined;
    // 初始化加载状态，编辑模式下为true。返回当前的状态和更新该状态的函数
    const [loading, setLoading] = useState(isEdit);
    // 取消操作处理函数，调用父组件的关闭函数
    const handleCancel = () => {
        props.handleClose()
    };
    // 初始化输入字段的默认值
    const originInputs = {
        name: '',
        type: 1,
        key: '',
        openai_organization: '',
        base_url: '',
        other: '',
        model_mapping: '',
        models: [],
        auto_ban: 1,
        groups: ['default']
    };
    // 使用useState管理各种状态
    const [batch, setBatch] = useState(false);
    const [autoBan, setAutoBan] = useState(true);
    // const [autoBan, setAutoBan] = useState(true);
    const [inputs, setInputs] = useState(originInputs);
    const [originModelOptions, setOriginModelOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);
    const [groupOptions, setGroupOptions] = useState([]);
    const [basicModels, setBasicModels] = useState([]);
    const [fullModels, setFullModels] = useState([]);
    const [customModel, setCustomModel] = useState('');
    // AWS配置状态
    const [config, setConfig] = useState({
        region: '',
        sk: '',
        ak: '',
    });

    // 处理输入变化的函数，动态更新输入状态
    const handleInputChange = (name, value) => {
        // 通过调用 setInputs 更新 inputs 状态。在不直接修改原始状态的情况下更新状态，从而触发组件的重新渲染
        setInputs((inputs) => ({ ...inputs, [name]: value }));
        if (name === 'type' && inputs.models.length === 0) {
            let localModels = [];
            switch (value) {
                case 8:
                    localModels = ["aws-claude-instant-1.2", "aws-claude-2", "aws-claude-2.0", "aws-claude-2.1", "aws-claude-3-opus-20240229", "aws-claude-3-sonnet-20240229", "aws-claude-3-haiku-20240307","aws-claude-3-5-haiku-20241022","aws-claude-3-5-sonnet-20241022","aws-claude-3-5-sonnet-20240620"];
                    break;
                case 3:
                    localModels = ["claude-instant-1.2", "claude-2", "claude-2.0", "claude-2.1", "claude-3-opus-20240229", "claude-3-sonnet-20240229", "claude-3-haiku-20240307","claude-3-5-sonnet-20240620"];
                    break;
                case 4:
                    localModels = ['chatglm_pro', 'chatglm_std', 'chatglm_lite'];
                    break;
                case 5:
                    localModels = [ 
                        "gemini-1.5-flash-latest",
                        "gemini-1.5-flash",
                        "gemini-1.5-flash-001",
                        "gemini-1.5-flash-002",
                        "gemini-1.5-flash-8b-exp-0924",
                        "gemini-1.5-flash-8b-exp-0827",
                        "gemini-1.5-flash-exp-0827",
                        
                        "gemini-1.5-pro-latest",
                        "gemini-1.5-pro",
                        "gemini-1.5-pro-001",
                        "gemini-1.5-pro-002",
                        "gemini-1.5-pro-exp-0827",

                        "gemini-1.0-pro-latest",
                        "gemini-1.0-pro",
                        "gemini-1.0-pro-001",
                    ];
                    break;
                case 6:
                    localModels = ['moonshot-v1-8k', 'moonshot-v1-32k', 'moonshot-v1-128k'];
                    break;
                case 9:
                    localModels = [
                        "qwen-max","qwen-max-latest","qwen-max-0919","qwen-max-0428","qwen-max-0403","qwen-max-0107","qwen-max-longcontext",	"qwen-plus","qwen-plus-latest","qwen-plus-0919","qwen-plus-0806","qwen-plus-0723","qwen-plus-0624","qwen-plus-0206",
                        "qwen-turbo","qwen-turbo-latest","qwen-turbo-0919","qwen-turbo-0624","qwen-turbo-0206",	"qwen-coder-turbo","qwen-coder-turbo-latest","qwen-coder-turbo-0919",	"qwen-long",
                        "qwen-vl-max","qwen-vl-max-0809","qwen-vl-plus-0809","qwen-vl-plus-0201","qwen-vl-plus",	"qwen-math-plus","qwen-math-plus-latest","qwen-math-plus-0919","qwen-math-plus-0816","qwen-math-turbo","qwen-math-turbo-latest","qwen-math-turbo-0919",
                        "qwen-audio-turbo",	"qwen2.5-72b-instruct","qwen2.5-32b-instruct","qwen2.5-14b-instruct","qwen2.5-7b-instruct","qwen2.5-3b-instruct","qwen2.5-1.5b-instruct","qwen2.5-0.5b-instruct",
                        "qwen2-72b-instruct","qwen2-57b-a14b-instruct","qwen2-7b-instruct",	"llama3.1-8b-instruct","llama3.1-70b-instruct","llama3.1-405b-instruct",
                        "yi-large","yi-large-rag","yi-medium","yi-large-turbo",	"flux-schnell","flux-dev","flux-merged",
                        "text-embedding-v1",
                    ];
                    break;
                case 10:
                    localModels = ["Doubao-pro-128k","Doubao-pro-32k","Doubao-pro-4k","Doubao-lite-128k","Doubao-lite-32k","Doubao-lite-4k","Doubao-embedding",];
                    break;   
                case 11:
                    localModels = ["deepseek-chat",];
                    break;   
            }
            setInputs((inputs) => ({ ...inputs, models: localModels }));
        }
        //setAutoBan
    };

    // 处理AWS配置变化
    const handleConfigChange = (e, { name, value }) => {
        setConfig((inputs) => ({ ...inputs, [name]: value }));
    };

    const loadChannel = async () => {
        setLoading(true)
        let res = await API.get(`/api/channel/${channelId}`);
        const { success, message, data } = res.data;
        if (success) {
            if (data.models === '') {
                data.models = [];
            } else {
                data.models = data.models.split(',');
            }
            if (data.group === '') {
                data.groups = [];
            } else {
                data.groups = data.group.split(',');
            }
            if (data.model_mapping !== '') {
                data.model_mapping = JSON.stringify(JSON.parse(data.model_mapping), null, 2);
            }
            setInputs(data);
            if (data.config !== '') {
                setConfig(JSON.parse(data.config));
            }
            if (data.auto_ban === 0) {
                setAutoBan(false);
            } else {
                setAutoBan(true);
            }
            // console.log(data);
        } else {
            showError(message);
        }
        setLoading(false);
    };

    const fetchModels = async () => {
        try {
            let res = await API.get(`/api/channel/models`);
            let localModelOptions = res.data.data.map((model) => ({
                label: model.id,
                value: model.id
            }));
            setOriginModelOptions(localModelOptions);
            setFullModels(res.data.data.map((model) => model.id));
            setBasicModels(res.data.data.filter((model) => {
                return model.id.startsWith('gpt-3') || model.id.startsWith('text-');
            }).map((model) => model.id));
        } catch (error) {
            showError(error.message);
        }
    };

    const fetchGroups = async () => {
        try {
            let res = await API.get(`/api/group/`);
            setGroupOptions(res.data.data.map((group) => ({
                label: group,
                value: group
            })));
        } catch (error) {
            showError(error.message);
        }
    };

    useEffect(() => {
        let localModelOptions = [...originModelOptions];
        inputs.models.forEach((model) => {
            if (!localModelOptions.find((option) => option.key === model)) {
                localModelOptions.push({
                    label: model,
                    value: model
                });
            }
        });
        setModelOptions(localModelOptions);
    }, [originModelOptions, inputs.models]);

    useEffect(() => {
        fetchModels().then();
        fetchGroups().then();
        if (isEdit) {
            loadChannel().then(
                () => {

                }
            );
        } else {
            setInputs(originInputs)
        }
    }, [props.editingChannel.id]);


    const submit = async () => {
        // aws
        if (inputs.key === '') {
            if (config.ak !== '' && config.sk !== '' && config.region !== '') {
              inputs.key = `${config.ak}|${config.sk}|${config.region}`;
            }
          }
        if (!isEdit && (inputs.name === '' || inputs.key === '')) {
            showInfo('请填写渠道名称和渠道密钥！');
            return;
        }
        if (inputs.models.length === 0) {
            showInfo('请至少选择一个模型！');
            return;
        }
        if (inputs.model_mapping !== '' && !verifyJSON(inputs.model_mapping)) {
            showInfo('模型映射必须是合法的 JSON 格式！');
            return;
        }
        let localInputs = { ...inputs };
        if (localInputs.base_url && localInputs.base_url.endsWith('/')) {
            localInputs.base_url = localInputs.base_url.slice(0, localInputs.base_url.length - 1);
        }
        if (localInputs.type === 2 && localInputs.other === '') {
            localInputs.other = '2024-03-01-preview';
        }
        let res;
        if (!Array.isArray(localInputs.models)) {
            showError('提交失败，请勿重复提交！');
            handleCancel();
            return;
        }
        localInputs.auto_ban = autoBan ? 1 : 0;
        localInputs.models = localInputs.models.join(',');
        localInputs.group = localInputs.groups.join(',');
        if (isEdit) {
            res = await API.put(`/api/channel/`, { ...localInputs, id: parseInt(channelId) });
        } else {
            res = await API.post(`/api/channel/`, localInputs);
        }
        const { success, message } = res.data;
        if (success) {
            if (isEdit) {
                showSuccess('渠道更新成功！');
            } else {
                showSuccess('渠道创建成功！');
                setInputs(originInputs);
            }
            props.refresh();
            props.handleClose();
        } else {
            showError(message);
        }
    };

    const addCustomModel = () => {
        if (customModel.trim() === '') return;
        if (inputs.models.includes(customModel)) return showError("该模型已存在！");
        let localModels = [...inputs.models];
        localModels.push(customModel);
        let localModelOptions = [];
        localModelOptions.push({
            key: customModel,
            text: customModel,
            value: customModel
        });
        setModelOptions(modelOptions => {
            return [...modelOptions, ...localModelOptions];
        });
        setCustomModel('');
        handleInputChange('models', localModels);
    };

    return (
        <>
            <SideSheet
                maskClosable={false}
                placement={isEdit ? 'right' : 'left'}
                title={<Title level={3}>{isEdit ? '更新渠道信息' : '创建新的渠道'}</Title>}
                headerStyle={{ borderBottom: '1px solid var(--semi-color-border)' }}
                bodyStyle={{ borderBottom: '1px solid var(--semi-color-border)' }}
                visible={props.visible}
                footer={
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Space>
                            <Button theme='solid' size={'large'} onClick={submit}>提交</Button>
                            <Button theme='solid' size={'large'} type={'tertiary'} onClick={handleCancel}>取消</Button>
                        </Space>
                    </div>
                }
                closeIcon={null}
                onCancel={() => handleCancel()}
                width={isMobile() ? '100%' : 600}
            >
                <Spin spinning={loading}>
                    <div style={{ marginTop: 10 }}>
                        <Typography.Text strong>类型：</Typography.Text>
                    </div>
                    <Select
                        name='type'
                        required
                        optionList={CHANNEL_OPTIONS}
                        value={inputs.type}
                        onChange={value => handleInputChange('type', value)}
                        style={{ width: '50%' }}
                    />
                    {
                        inputs.type === 2 && (
                            <>
                                <div style={{ marginTop: 10 }}>
                                    <Banner type={"warning"} description={
                                        <>
                                            注意，<strong>模型部署名称必须和模型名称保持一致</strong>，因为 One API 会把请求体中的
                                            model
                                            参数替换为你的部署名称（模型名称中的点会被剔除），<a target='_blank'
                                                href='https://github.com/songquanpeng/one-api/issues/133?notification_referrer_id=NT_kwDOAmJSYrM2NjIwMzI3NDgyOjM5OTk4MDUw#issuecomment-1571602271'>图片演示</a>。
                                        </>
                                    }>
                                    </Banner>
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    <Typography.Text strong>AZURE_OPENAI_ENDPOINT：</Typography.Text>
                                </div>
                                <Input
                                    label='AZURE_OPENAI_ENDPOINT'
                                    name='azure_base_url'
                                    placeholder={'请输入 AZURE_OPENAI_ENDPOINT，例如：https://docs-test-001.openai.azure.com'}
                                    onChange={value => {
                                        handleInputChange('base_url', value)
                                    }}
                                    value={inputs.base_url}
                                    autoComplete='new-password'
                                />
                                <div style={{ marginTop: 10 }}>
                                    <Typography.Text strong>默认 API 版本：</Typography.Text>
                                </div>
                                <Input
                                    label='默认 API 版本'
                                    name='azure_other'
                                    placeholder={'请输入默认 API 版本，例如：2024-03-01-preview，该配置可以被实际的请求查询参数所覆盖'}
                                    onChange={value => {
                                        handleInputChange('other', value)
                                    }}
                                    value={inputs.other}
                                    autoComplete='new-password'
                                />
                            </>
                        )
                    }
                    {
                        inputs.type === 7 && (
                            <>
                                <div style={{ marginTop: 10 }}>
                                    <Typography.Text strong>Base URL：</Typography.Text>
                                </div>
                                <Input
                                    name='base_url'
                                    placeholder={'请输入自定义渠道的 Base URL'}
                                    onChange={value => {
                                        handleInputChange('base_url', value)
                                    }}
                                    value={inputs.base_url}
                                    autoComplete='new-password'
                                />
                            </>
                        )
                    }

                    <div style={{ marginTop: 10 }}>
                        <Typography.Text strong>名称：</Typography.Text>
                    </div>
                    <Input
                        required
                        name='name'
                        placeholder={'请为渠道命名'}
                        onChange={value => {
                            handleInputChange('name', value)
                        }}
                        value={inputs.name}
                        autoComplete='new-password'
                    />

                    <div style={{ marginTop: 10 }}>
                        <Typography.Text strong>分组：</Typography.Text>
                    </div>
                    <Select
                        placeholder={'请选择可以使用该渠道的分组'}
                        name='groups'
                        required
                        multiple
                        selection
                        allowAdditions
                        additionLabel={'请在系统设置页面编辑分组倍率以添加新的分组：'}
                        onChange={value => {
                            handleInputChange('groups', value)
                        }}
                        value={inputs.groups}
                        autoComplete='new-password'
                        optionList={groupOptions}
                    />

                    <div style={{ marginTop: 10 }}>
                        <Typography.Text strong>模型：</Typography.Text>
                    </div>
                    <Select
                        placeholder={'请选择该渠道所支持的模型'}
                        name='models'
                        required
                        multiple
                        selection
                        onChange={value => {
                            handleInputChange('models', value)
                        }}
                        value={inputs.models}
                        autoComplete='new-password'
                        optionList={modelOptions}
                    />
                    <div style={{ lineHeight: '40px', marginBottom: '12px' }}>
                        <Space>
                            <Button type='primary' onClick={() => {
                                handleInputChange('models', basicModels);
                            }}>填入基础模型</Button>
                            <Button type='secondary' onClick={() => {
                                handleInputChange('models', fullModels);
                            }}>填入所有模型</Button>
                            <Button type='warning' onClick={() => {
                                handleInputChange('models', []);
                            }}>清除所有模型</Button>
                        </Space>
                        <Input
                            addonAfter={
                                <Button type='primary' onClick={addCustomModel}>填入</Button>
                            }
                            placeholder='输入自定义模型名称'
                            value={customModel}
                            onChange={(value) => {
                                setCustomModel(value.trim());
                            }}
                        />
                    </div>

                    <div style={{ marginTop: 10 }}>
                        <Typography.Text strong>模型重定向：</Typography.Text>
                    </div>
                    <TextArea
                        placeholder={`此项可选，用于修改请求体中的模型名称，为一个 JSON 字符串，键为请求中模型名称，值为要替换的模型名称，例如：\n${JSON.stringify(MODEL_MAPPING_EXAMPLE, null, 2)}`}
                        name='model_mapping'
                        onChange={value => {
                            handleInputChange('model_mapping', value)
                        }}
                        autosize
                        value={inputs.model_mapping}
                        autoComplete='new-password'
                    />
                    <Typography.Text style={{
                        color: 'rgba(var(--semi-blue-5), 1)',
                        userSelect: 'none',
                        cursor: 'pointer'
                    }} onClick={
                        () => {
                            handleInputChange('model_mapping', JSON.stringify(MODEL_MAPPING_EXAMPLE, null, 2))
                        }
                    }>
                        填入模板
                    </Typography.Text>

                    {
                        inputs.type === 88 ? (
                            <Form.Field>
                            <Form.Input
                              label='Region'
                              name='region'
                              required
                              placeholder={'region，e.g. us-west-2'}
                              onChange={handleConfigChange}
                              value={config.region}
                              autoComplete=''
                            />
                            <Form.Input
                              label='AK'
                              name='ak'
                              required
                              placeholder={'AWS IAM Access Key'}
                              onChange={handleConfigChange}
                              value={config.ak}
                              autoComplete=''
                            />
                            <Form.Input
                              label='SK'
                              name='sk'
                              required
                              placeholder={'AWS IAM Secret Key'}
                              onChange={handleConfigChange}
                              value={config.sk}
                              autoComplete=''
                            />
                          </Form.Field>
                        ) : (
                            <>
                                <div style={{ marginTop: 10 }}>
                                    <Typography.Text strong>密钥：</Typography.Text>
                                </div>
                                {
                                    batch ?
                                        <TextArea
                                            name='key'
                                            required
                                            placeholder={'请输入密钥，一行一个'}
                                            onChange={(value) => handleInputChange('key', value)}
                                            value={inputs.key}
                                            style={{ minHeight: 150, fontFamily: 'JetBrains Mono, Consolas' }}
                                            autoComplete='new-password'
                                        />
                                        :
                                        <Input
                                            name='key'
                                            required
                                            placeholder={type2secretPrompt(inputs.type)}
                                            onChange={(value) => handleInputChange('key', value)}
                                            value={inputs.key}
                                            autoComplete='new-password'
                                        />
                                }
                            </>
                        )
                    }

                    <div style={{ marginTop: 10 }}>
                        <Typography.Text strong>组织：</Typography.Text>
                    </div>
                    <Input
                        label='组织，可选，不填则为默认组织'
                        name='openai_organization'
                        placeholder='请输入组织org-xxx'
                        onChange={value => {
                            handleInputChange('openai_organization', value)
                        }}
                        value={inputs.openai_organization}
                    />
                    <div style={{ marginTop: 10, display: 'flex' }}>
                        <Space>
                            <Checkbox
                                name='auto_ban'
                                checked={autoBan}
                                onChange={
                                    () => {
                                        setAutoBan(!autoBan);
                                    }
                                }
                            // onChange={handleInputChange}
                            />
                            <Typography.Text
                                strong>是否自动禁用（仅当自动禁用开启时有效），关闭后不会自动禁用该渠道：</Typography.Text>
                        </Space>
                    </div>

                    {
                        !isEdit && (
                            <div style={{ marginTop: 10, display: 'flex' }}>
                                <Space>
                                    <Checkbox
                                        checked={batch}
                                        label='批量创建'
                                        name='batch'
                                        onChange={() => setBatch(!batch)}
                                    />
                                    <Typography.Text strong>批量创建</Typography.Text>
                                </Space>
                            </div>
                        )
                    }
                    {
                        inputs.type !== 7 && (
                            <>
                                <div style={{ marginTop: 10 }}>
                                    <Typography.Text strong>代理：</Typography.Text>
                                </div>
                                <Input
                                    label='代理'
                                    name='base_url'
                                    placeholder={'此项可选，用于通过代理站来进行 API 调用'}
                                    onChange={value => {
                                        handleInputChange('base_url', value)
                                    }}
                                    value={inputs.base_url}
                                    autoComplete='new-password'
                                />
                            </>
                        )
                    }
                </Spin>
            </SideSheet>
        </>
    );
};

export default EditChannel;
