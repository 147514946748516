import React, { useState } from 'react';
import { Button, Form, Input, Slider, Switch, message, List, Card, Row, Col, InputNumber, Select, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
// import 'antd/dist/antd.css';
// import './highlight.css';

const { Option } = Select;

const Playground = () => {
  const [formData, setFormData] = useState({
    model: 'gpt-3.5-turbo',
    messages: [],
    systemPrompt: '',
    temperature: 0.7,
    topP: 0.9,
    topK: 0.9,
    stream: false,
    maxTokens: 64,
    useSystemPrompt: false,
    isSimplified: true,
  });

  const modelsSimplified = [
    "gpt-4o",
    "gpt-4o-mini",
    "gpt-3.5-turbo",
    "gpt-4",

    "aws-claude-3-5-haiku-20241022",
    "aws-claude-3-5-sonnet-20241022",
    "aws-claude-3-sonnet-20240229",
    "aws-claude-3-opus-20240229",

    "gemini-1.5-flash",
    "gemini-1.5-pro",
    "gemini-1.0-pro",

    "moonshot-v1-8k",
    "moonshot-v1-32k",
    "moonshot-v1-128k",

    "glm-4-0520", "glm-4-air",

    "qwen-turbo",
    "qwen-plus",
    "qwen-max",
    "llama3.1-8b-instruct", "llama3.1-70b-instruct", "llama3.1-405b-instruct",
    "yi-large","yi-large-rag","yi-medium","yi-large-turbo","qwen-vl-plus","qwen-vl-max",
    "flux-schnell","flux-dev","flux-merged",
    
    "Doubao-pro-128k",
    "Doubao-lite-128k",

    "deepseek-chat",
  ];

  const models = [
    "gpt-4o", "gpt-4o-mini", 
    "gpt-4o-mini-2024-07-18", "gpt-4o-2024-08-06",
    "o1-preview","o1-preview-2024-09-12",
    "o1-mini","o1-mini-2024-09-12",
    "gpt-3.5-turbo", "gpt-3.5-turbo-0301", "gpt-3.5-turbo-0613", "gpt-3.5-turbo-1106", "gpt-3.5-turbo-0125",
    "gpt-3.5-turbo-16k", "gpt-3.5-turbo-16k-0613",
    "gpt-3.5-turbo-instruct",
    "gpt-4", "gpt-4-0314", "gpt-4-0613", "gpt-4-1106-preview", "gpt-4-0125-preview",
    "gpt-4-32k", "gpt-4-32k-0314", "gpt-4-32k-0613",
    "gpt-4-turbo-preview", "gpt-4-turbo", "gpt-4-turbo-2024-04-09", 
    "gpt-4-vision-preview",
    "text-embedding-ada-002", "text-embedding-3-small", "text-embedding-3-large",
    "text-curie-001", "text-babbage-001", "text-ada-001", "text-davinci-002", "text-davinci-003",
    "text-moderation-latest", "text-moderation-stable",
    "text-davinci-edit-001",
    "davinci-002", "babbage-002",
    "dall-e-2", "dall-e-3",
    "whisper-1",
    "tts-1", "tts-1-1106", "tts-1-hd", "tts-1-hd-1106",

    "claude-instant-1.2", "claude-2.0", "claude-2.1",
    "claude-3-haiku-20240307",
    "claude-3-sonnet-20240229",
    "claude-3-opus-20240229",
    "claude-3-5-sonnet-20240620",


    "aws-claude-instant-1.2", "aws-claude-2.0", "aws-claude-2.1",
    "aws-claude-3-haiku-20240307",
    "aws-claude-3-sonnet-20240229",
    "aws-claude-3-opus-20240229",
    "aws-claude-3-5-sonnet-20240620",
    "aws-claude-3-5-haiku-20241022",
    "aws-claude-3-5-sonnet-20241022",

    "gemini-1.5-flash-latest",
    "gemini-1.5-flash",
    "gemini-1.5-flash-001",
    "gemini-1.5-flash-002",
    "gemini-1.5-flash-8b-exp-0924",
    "gemini-1.5-flash-8b-exp-0827",
    "gemini-1.5-flash-exp-0827",
    "gemini-1.5-pro-latest",
    "gemini-1.5-pro",
    "gemini-1.5-pro-001",
    "gemini-1.5-pro-002",
    "gemini-1.5-pro-exp-0827",
    "gemini-1.0-pro-latest",
    "gemini-1.0-pro",
    "gemini-1.0-pro-001",

    "moonshot-v1-8k",
    "moonshot-v1-32k",
    "moonshot-v1-128k",

    "embedding-2","embedding-3",
    "glm-4", "glm-4v", "glm-3-turbo", "glm-4-0520", "glm-4-air", "glm-4-airx", "glm-4-flash",

    "qwen-max","qwen-max-latest","qwen-max-0919","qwen-max-0428","qwen-max-0403","qwen-max-0107","qwen-max-longcontext",
    "qwen-plus","qwen-plus-latest","qwen-plus-0919","qwen-plus-0806","qwen-plus-0723","qwen-plus-0624","qwen-plus-0206",
    "qwen-turbo","qwen-turbo-latest","qwen-turbo-0919","qwen-turbo-0624","qwen-turbo-0206",
    "qwen-coder-turbo","qwen-coder-turbo-latest","qwen-coder-turbo-0919",
    "qwen-long",
    "qwen-vl-max","qwen-vl-max-0809","qwen-vl-plus-0809","qwen-vl-plus-0201","qwen-vl-plus",
    "qwen-math-plus","qwen-math-plus-latest","qwen-math-plus-0919","qwen-math-plus-0816","qwen-math-turbo","qwen-math-turbo-latest","qwen-math-turbo-0919",
    "qwen-audio-turbo",
    "qwen2.5-72b-instruct","qwen2.5-32b-instruct","qwen2.5-14b-instruct","qwen2.5-7b-instruct","qwen2.5-3b-instruct","qwen2.5-1.5b-instruct","qwen2.5-0.5b-instruct",
    "qwen2-72b-instruct","qwen2-57b-a14b-instruct","qwen2-7b-instruct",
    "llama3.1-8b-instruct","llama3.1-70b-instruct","llama3.1-405b-instruct",
    "yi-large","yi-large-rag","yi-medium","yi-large-turbo",
    "flux-schnell","flux-dev","flux-merged",
    "text-embedding-v1",
    
    "Doubao-pro-128k",
    "Doubao-pro-32k",
    "Doubao-pro-4k",
    "Doubao-lite-128k",
    "Doubao-lite-32k",
    "Doubao-lite-4k",
    "Doubao-embedding",

    "deepseek-chat",
  ];

  const generateCode = () => {
    const systemMessage = formData.useSystemPrompt ? [{ role: 'system', content: formData.systemPrompt }] : [];
    const adjustedMessages = [...systemMessage, ...formData.messages];
    return `
  from openai import OpenAI
  
  client = OpenAI(
    api_key = 'Your API Key',
    base_url = 'https://ganjiuwanshi.com/v1/'
  )
  
  response = client.chat.completions.create(
    model="${formData.model}",
    messages=${JSON.stringify(adjustedMessages, null, 2)},
    temperature=${formData.temperature},
    max_tokens=${formData.maxTokens},
    top_p=${formData.topP},
    stream=${formData.stream ? 'True' : 'False'}
  )
  
  print(response)`;
  };


  const handleAddMessage = () => {
    const newMessages = [...formData.messages, { role: 'user', content: '' }];
    setFormData({ ...formData, messages: newMessages });
  };

  const handleRemoveMessage = () => {
    const newMessages = formData.messages.slice(0, -1);
    setFormData({ ...formData, messages: newMessages });
  };

  const handleUpdateMessage = (index, field, value) => {
    const newMessages = [...formData.messages];
    newMessages[index][field] = value;
    setFormData({ ...formData, messages: newMessages });
  };

  const copyCodeToClipboard = () => {
    navigator.clipboard.writeText(generateCode());
    message.success('Code copied to clipboard!');
  };
  return (
    <div>
      <h3 style={{ textAlign: 'left', marginBottom: '20px' }}>Playground</h3>
      <Row gutter={16} style={{ margin: '50px' }}>
        <Col span={12}>
          <Input.TextArea rows={20} value={generateCode()} style={{ height: '100%', fontSize: '16px', fontFamily: 'monospace' }} />
          <Button type="primary" onClick={copyCodeToClipboard} style={{ marginTop: '10px' }}>复制代码</Button>
        </Col>
        <Col span={12}>
          <Card bordered={true} style={{ width: '100%', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
            <Form layout="vertical">
              <Form.Item label={
                <span>
                  模型 <Tooltip title="用于生成回复的模型。"><InfoCircleOutlined /></Tooltip>
                  <hr/>
                  简化模型 <Tooltip title="简化显示的模型，关闭则显示全部可选模型"><InfoCircleOutlined /></Tooltip>
                  <Switch style={{marginLeft: '20px'}} checked={formData.isSimplified} onChange={checked => setFormData({ ...formData, isSimplified: checked })} />
                </span>
              }>
                <Select defaultValue={formData.model} style={{ width: '100%' }} onChange={value => setFormData({ ...formData, model: value })}>
                  {formData.isSimplified ?
                    modelsSimplified.map(model => (
                      <Option key={model} value={model}>{model}</Option>
                    ))
                    :
                    models.map(model => (
                      <Option key={model} value={model}>{model}</Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label={
                <span>
                  system Prompt 系统提示词 <Tooltip title="是否使用系统级提示来引导回答。系统人设，例如“你是一个AI助手"><InfoCircleOutlined /></Tooltip>
                </span>
              }>
                <Switch checked={formData.useSystemPrompt} onChange={checked => setFormData({ ...formData, useSystemPrompt: checked })} />
              </Form.Item>
              {formData.useSystemPrompt && (
                <Form.Item label="System Prompt">
                  <Input value={formData.systemPrompt} onChange={e => setFormData({ ...formData, systemPrompt: e.target.value })} style={{ width: '100%' }} />
                </Form.Item>
              )}
              <Form.Item label={
                <span>
                  Temperature 温度<Tooltip title="控制输出的随机性。数值越小，模型的确定性越强。"><InfoCircleOutlined /></Tooltip>
                </span>
              }>
                <Slider min={0} max={1} step={0.01} value={formData.temperature} onChange={value => setFormData({ ...formData, temperature: value })} />
              </Form.Item>
              <Form.Item label={
                <span>
                  Top P <Tooltip title="控制生成回复的范围或集中程度。数值越小，集中的标记越少。"><InfoCircleOutlined /></Tooltip>
                </span>
              }>
                <Slider min={0} max={1} step={0.01} value={formData.topP} onChange={value => setFormData({ ...formData, topP: value })} />
              </Form.Item>
              {/* <Form.Item label={
                <span>
                  Top K <Tooltip title="将所考虑的标记集限制为前 k 个选择。较低的值会降低出现令人惊讶的回答的几率。"><InfoCircleOutlined /></Tooltip>
                </span>
              }>
                <Slider min={1} max={100} step={1} value={formData.topK} onChange={value => setFormData({...formData, topK: value})} />
              </Form.Item> */}
              <Form.Item label={
                <span>
                  Max Tokens <Tooltip title="模型在每次响应中能生成的最大标记数。"><InfoCircleOutlined /></Tooltip>
                </span>
              }>
                <InputNumber min={1} max={512} style={{ width: '100%' }} value={formData.maxTokens} onChange={value => setFormData({ ...formData, maxTokens: value })} />
              </Form.Item>
              <Form.Item label={
                <span>
                  Stream 流式输出<Tooltip title="是流式输出还是等到完成后再返回响应。"><InfoCircleOutlined /></Tooltip>
                </span>
              }>
                <Switch checked={formData.stream} onChange={checked => setFormData({ ...formData, stream: checked })} />
              </Form.Item>
              <Form.Item label="Messages">
                <Button onClick={handleAddMessage}>Add Message</Button>
                <Button onClick={handleRemoveMessage} disabled={formData.messages.length === 0} style={{ marginLeft: '10px' }}>Remove Last Message</Button>
                <List
                  dataSource={formData.messages}
                  renderItem={(item, index) => (
                    <List.Item>
                      <Card title={`Message ${index + 1}`} style={{ width: '100%' }}>
                        <Select
                          defaultValue={item.role}
                          style={{ width: '100%', marginBottom: '10px' }}
                          onChange={value => handleUpdateMessage(index, 'role', value)}
                        >
                          <Option value="user">User</Option>
                          <Option value="assistant">Assistant</Option>
                        </Select>
                        <Input
                          placeholder="Content"
                          value={item.content}
                          onChange={e => handleUpdateMessage(index, 'content', e.target.value)}
                          style={{ width: '100%' }}
                        />
                      </Card>
                    </List.Item>
                  )}
                />
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Playground;
